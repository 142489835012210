import React from 'react';
import PropTypes from 'prop-types';

const PostExcerpt = props => {
  const { acf } = props;

  if (acf.post_excerpt) {
    return <p>{acf.post_excerpt.slice(0, 120)} ...</p>;
  }
  return '';
};

const EventCard = props => {
  const { title, acf, link, type } = props;

  return (
    <div className="col-lg-6 my-4">
      <div className="event-card">
        <a href={link}>
          <h2>
            <span
              className="underline"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: title.rendered }}
            >
              {/* {title.rendered.replace('&#038;', '&')} */}
            </span>
          </h2>
        </a>

        <h3>
          {acf.event_time
            ? `${acf.event_date}, ${acf.event_time.toUpperCase()}`
            : `${acf.event_date}`}
        </h3>

        {acf.composers
          ? acf.composers.map(e => (
              <h3 className="artegra__bold-1--italic">{e.composer}</h3>
            ))
          : ''}

        <PostExcerpt acf={acf} />

        {type === 'watch_and_listen' ? (
          <a href={link}>
            <button type="button" className="btn__cta">
              GO TO BROADCAST
            </button>
          </a>
        ) : (
          <a href={link}>
            <button type="button" className="btn__cta">
              READ MORE
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

EventCard.propTypes = {
  title: PropTypes.object,
  acf: PropTypes.object,
  link: PropTypes.string,
  type: PropTypes.string,
};

PostExcerpt.propTypes = {
  acf: PropTypes.object,
};

export default EventCard;
