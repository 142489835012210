import React from 'react';
import ReactDOM from 'react-dom';
// import NewsArchive from './components/News/NewsArchive.js';
import EventsLanding from './components/Events/EventsLanding';
import 'bootstrap/dist/css/bootstrap.css';

if (document.getElementById('watch-and-listen')) {
  ReactDOM.render(
    <EventsLanding page="watch_and_listen" />,
    document.getElementById('watch-and-listen')
  );
}

if (document.getElementById('program-notes')) {
  ReactDOM.render(
    <EventsLanding page="program_notes" />,
    document.getElementById('program-notes')
  );
}
