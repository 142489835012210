import React from 'react';
import PropTypes from 'prop-types';

const Pagination = props => {
  const { items, elementsPerPage, currentPage, onPaginate } = props;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / elementsPerPage); i += 1) {
    pageNumbers.push(i);
  }

  const pageClasses = 'page-number page-numbers';

  if (pageNumbers.length > 1) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="tool-pagination pb-0">
            <ul className="pages">
              {pageNumbers.map(number => (
                <li
                  className={
                    number === currentPage
                      ? `${pageClasses} current`
                      : pageClasses
                  }
                  key={number}
                >
                  <button
                    type="button"
                    id={number}
                    style={{
                      WebkitAppearance: 'none',
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() => onPaginate(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  return <React.Fragment />;
};

Pagination.propTypes = {
  items: PropTypes.array,
  elementsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  onPaginate: PropTypes.func,
};

export default Pagination;
