import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventArchive from './EventArchive';
import Pagination from '../Pagination';

export default class EventsLanding extends Component {
  state = {
    current: true,
    futureEvents: [],
    pastEvents: [],
    currentPage: 1,
    elementsPerPage: 4,
    loaded: false,
  };

  componentDidMount() {
    const { page } = this.props;
    let url;
    // const url = `http://localhost/svs/wp-json/wp/v2/${page}`;

    if (window.location.href.indexOf('svmusicfestival') !== -1) {
      url = `/wp-json/wp/v2/${page}?per_page=100`;
    } else {
      url = `https://svs.sbadev.com/wp-json/wp/v2/${page}?per_page=100`;
    }

    const today = new Date();
    const splittedDates = {
      futureEvents: [],
      pastEvents: [],
    };

    fetch(url)
      .then(res => res.json())
      .then(res => {
        res.map(e => {
          new Date(e.acf.event_date).setHours(0, 0, 0, 0) >=
          today.setHours(0, 0, 0, 0)
            ? splittedDates.futureEvents.push(e)
            : splittedDates.pastEvents.push(e);
          return e;
        });

        return splittedDates;
      })
      .then(res => {
        let { futureEvents, pastEvents } = res;

        futureEvents = futureEvents.sort(function(a, b) {
          return new Date(a.acf.event_date) - new Date(b.acf.event_date);
        });

        pastEvents = pastEvents.sort(function(a, b) {
          return new Date(b.acf.event_date) - new Date(a.acf.event_date);
        });

        this.setState(() => ({
          futureEvents,
          pastEvents,
          loaded: true,
        }));
      })
      .catch(err => console.error(err));
  }

  handleArchiveToggle = value => {
    this.setState(() => ({
      current: value,
      currentPage: 1,
    }));
  };

  handlePagination = page => {
    this.setState(() => ({
      currentPage: Number(page),
    }));
  };

  render() {
    const {
      current,
      futureEvents,
      pastEvents,
      currentPage,
      elementsPerPage,
      loaded,
    } = this.state;
    const { page } = this.props;

    return (
      <div className="container">
        {pastEvents.length > 0 ? (
          <div className="row section__container mb-0">
            <div className="col-lg-6">
              <button
                onClick={() => this.handleArchiveToggle(true)}
                type="button"
                className={current ? 'filter__toggle active' : 'filter__toggle'}
              >
                Upcoming
              </button>
              <button
                onClick={() => this.handleArchiveToggle(false)}
                type="button"
                className={current ? 'filter__toggle' : 'filter__toggle active'}
              >
                Past
              </button>
            </div>
          </div>
        ) : (
          ''
        )}

        {current ? (
          <React.Fragment>
            <EventArchive
              page={page}
              events={futureEvents}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              loaded={loaded}
            />

            <Pagination
              items={futureEvents}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              onPaginate={this.handlePagination}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <EventArchive
              page={page}
              events={pastEvents}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              loaded={loaded}
            />

            <Pagination
              items={pastEvents}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              onPaginate={this.handlePagination}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

EventsLanding.propTypes = {
  page: PropTypes.string,
};
