import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventCard from './EventCard';
import Spinner from './Spinner';

export default class EventArchive extends Component {
  renderCards() {
    const { events, currentPage, elementsPerPage, loaded } = this.props;
    const indexOfLastPerson = currentPage * elementsPerPage;
    const indexOfFirstPerson = indexOfLastPerson - elementsPerPage;
    const currentEvents = events.slice(indexOfFirstPerson, indexOfLastPerson);

    if (!loaded)
      return (
        <div className="col-lg-12 align-items-center justify-content-center d-flex">
          <Spinner />
        </div>
      );

    if (currentEvents.length > 0 && loaded)
      return currentEvents.map((e, i) => <EventCard key={i} {...e} />);

    if (currentEvents.length === 0 && loaded)
      return (
        <p>
          There are currently no Upcoming events. Please check the past ones.
        </p>
      );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row flex-column">{this.renderCards()}</div>
      </React.Fragment>
    );
  }
}

EventArchive.propTypes = {
  events: PropTypes.array,
  current: PropTypes.bool,
  page: PropTypes.string,
  currentPage: PropTypes.number,
  elementsPerPage: PropTypes.number,
  loaded: PropTypes.bool,
};
